import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Link } from "gatsby"
import {
  Heading,
  Text,
  Container,
  Box,
  Flex,
  Button
} from '@chakra-ui/react'

import { PrismicRichText, SliceZone } from '@prismicio/react'
import { components } from "../components/Slices"
import ShakespeareLogo from "../images/ShakespeareLogo.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PageSubMenu from "../components/PageSubMenu/PageSubMenu"


const ShakespearePage = ({ data, location }) => {
  const doc = data.allPrismicPage.edges[0].node.data;  

  return (
    <Layout>
      {doc.submenu.document !== null &&
      <PageSubMenu 
        menuItems={doc.submenu.document.data.page_submenu}
        location={location}
      />}
      <main>
        <Container maxW="container.xl">
          <Flex alignItems="center" textAlign="center" flexDirection="column" mb="24">
            <Box w={{base: "85%", lg: "60%"}} my="4">
              <img src={ShakespeareLogo} alt="Shakespeare In The Parks Logo" />
            </Box>
            <Box mb="12">
              <GatsbyImage image={getImage(doc.hero_image.localFile)} alt={doc.hero_image.alt} />
            </Box>
            <Box>
              <Heading as="h1" fontSize="5xl" mb="4">
                {doc.page_title.text}
              </Heading>
              <Heading as="h2" variant="h2" fontFamily="Roboto, sans-serif" fontSize="clamp(1rem, 2vw, 1.25rem)">
                {doc.page_subtitle.text}
              </Heading>
            </Box>
            {doc.submenu.document !== null && (
            <Flex w="100%" justifyContent="center" flexDirection={{base: 'column', md: 'row'}} flexWrap="wrap">
              {doc.submenu.document.data.page_submenu.map((item, index) => (
                <Link to={item.menu_item_link.url}>
                  <Button variant="snowscapes" m="4" size="lg" key={index}>{item.menu_item_label}</Button>
                </Link>
              ))}
            </Flex>)}
          </Flex>
        </Container>
        <SliceZone slices={doc.body} components={components} />
      </main>
    </Layout>
  )
}

export const query = graphql`
query pageQuery {
  allPrismicPage(filter: {uid: {eq: "shakespeare-in-the-parks"}}) {
    edges {
      node {
        data {
          page_title {
            text
          }
          page_subtitle {
            text
          }
          mobile_hero_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 800
                  width: 600
                  placeholder: BLURRED
                  transformOptions: {cropFocus: CENTER}
                )
              }
            }
          }
          hero_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 714
                  width: 1920
                  placeholder: BLURRED
                  transformOptions: {cropFocus: CENTER}
                )
              }
            }
          }
          body {
            ... on PrismicPageDataBodyNewsletterSignup {
              id
              slice_type
              primary {
                newsletter_signup_title {
                  text
                }
                newsletter_signup_subtitle {
                  text
                }
                newsletter_signup_button_label
                newsletter_signup_background_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
            ... on PrismicPageDataBodyCallToAction {
              id
              slice_type
              primary {
                link_label
                link {
                  url
                }
                call_to_action_title {
                  text
                }
                call_to_action_content {
                  richText
                }
                call_to_action_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
            ... on PrismicPageDataBodyGallery {
              id
              slice_type
              primary {
                gallery {
                  document {
                    ... on PrismicGallery {
                      id
                      data {
                        gallery_title {
                          text
                        }
                        gallery_image {
                          image {
                            alt
                            localFile {
                              childImageSharp {
                                full: gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                thumb: gatsbyImageData(placeholder: BLURRED, aspectRatio: 1
                                  transformOptions: {cropFocus: CENTER})
                              }
                            }
                          }
                          image_caption {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicPageDataBodyDowntownEvents {
              id
              primary {
                justify_image
                button_label
                button_link {
                  url
                }
                event_content {
                  richText
                }
                event_logo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                    publicURL
                  }
                  alt
                }
                event_title {
                  text
                }
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
              }
              items {
                event_fact_title {
                  text
                }
                event_fact_stat {
                  text
                }
              }
              slice_type
            }
            ... on PrismicPageDataBodyEventsCarousel {
              id
              primary {
                show_events_carousel
                carousel_title {
                  text
                }
              }
              slice_type
            }
            ... on PrismicPageDataBodyRichText {
              id
              slice_type
              items {
                content {
                  richText
                }
              }
            }
          }
          submenu {
            document {
              ... on PrismicPageSubmenu {
                id
                data {
                  page_submenu {
                    menu_item_label
                    menu_item_link {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default ShakespearePage

export const Head = ({ data }) => <title>{data.allPrismicPage.edges[0].node.data.page_title.text}</title>
